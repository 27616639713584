<template>
  <div class="edito">
    <img width="100%" src="../assets/images/bdform.png" />
    <br />
    <br />
    <p>
    
Nous avons bien enregistré votre demande de participation à l'offre "Avantage Quinzaine du Gaz 2021".
<br /><br /> 
Vous recevrez votre remboursement dans un délai de 4 à 6 semaines, sous réserve de conformité de votre dossier.
    </p>
    <br />
    <br />
  </div>
</template>
<script>
export default {
  name: 'Confirm',
}
</script>
